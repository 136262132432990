import React, { useState } from "react";
import "./Main2.scss";
import logo2 from "../Images/logo2.jpg";
import backblue from "../Images/backblue.jpg";
import mobile2 from "../Images/mobile2.jpg";
import mobile3 from "../Images/mobile3.jpg";
import mobile4 from "../Images/mobile4.jpg";
import mobile1 from "../Images/mobile1.jpg";
import blackdot from "../Images/blackdot.jpg";
import whitedot from "../Images/whitedot.jpg";
import midimg from "../Images/midimg.png";
import leftimg from "../Images/leftimg.png";
import rightimg from "../Images/rightimg.png";
import leftarrow from "../Images/leftarrow.png";
import rightarrow from "../Images/rightarrow.png";

const Main2 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleLeftArrowClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 3) % 3);
  };

  const handleRightArrowClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
  };

  return (
    <div className="background-image2">
      <div className="secondbackground">
        <img src={backblue} alt="" />
        <div className="left">
          <img src={logo2} alt="" />
          <div className="leftheading">
            <h1>Unlocking Potential</h1>
            <h2>Key Features & Functions</h2>
            <div className="para">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer
              </p>
            </div>
          </div>
          <div className="boxes">
            <div className="boxline1">
              <div className="boxnew">
                <p>Features - 1</p>
              </div>
              <div className="boxnew">
                <p>Features - 3</p>
              </div>
            </div>
            <div className="boxline2">
              <div className="boxnew">
                <p>Features - 2</p>
              </div>
              <div className="boxnew">
                <p>Features - 4</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="images1">
            <img src={mobile1} alt="" />
          </div>
          <div className="images2">
            <img src={mobile2} alt="" />
          </div>
          <div className="images3">
            <img src={mobile3} alt="" />
          </div>
          <div className="images4">
            <img src={mobile4} alt="" />
          </div>
        </div>
      </div>
      <div className="thirdpart">
        <h1>You are the center of our Universe</h1>
        <h2>Testimonials</h2>
        <div className="dots">
          <img src={blackdot} alt="" />
          <img src={whitedot} alt="" />
          <img src={blackdot} alt="" />
        </div>
        <div>
          {/* <div className="three-images">
            <div className="left-a" onClick={handlePrevClick}>
              <img src={leftarrow} alt="Left Arrow" />
            </div>
            <div className="par-div">
              <div className="firstimg">
                <img
                  src={
                    currentImageIndex === 0
                      ? leftimg
                      : currentImageIndex === 1
                      ? midimg
                      : rightimg
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="right-a" onClick={handleNextClick}>
              <img src={rightarrow} alt="Right Arrow" />
            </div>
          </div> */}

          <div className="down-content-slider">
            <div className="left-slidArrow" onClick={handleLeftArrowClick}>
              <img src={leftarrow} alt="" />
            </div>
            <div className="slider-three">
              <div
                className="slider1-img"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                <img src={leftimg} alt="" />
              </div>
              <div
                className="slider2-img"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                <img src={midimg} alt="" />
              </div>
              <div
                className="slider3-img"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                <img src={rightimg} alt="" />
              </div>
            </div>
            <div className="right-slidArrow" onClick={handleRightArrowClick}>
              <img src={rightarrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main2;
