// import React from "react";
import React, { useState } from "react";
import BaseComponent from "../BaseComponents/BaseComponent";
import "./AfterLogin.scss";

const AfterLogin = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      invoice: "INV-001",
      billingDate: "2022-01-01",
      amount: 100,
      plan: "Basic",
    },
    {
      id: 2,
      invoice: "INV-002",
      billingDate: "2022-02-01",
      amount: 150,
      plan: "Standard",
    },
    {
      id: 3,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 4,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 5,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 6,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 7,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 8,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 9,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
    {
      id: 10,
      invoice: "INV-003",
      billingDate: "2022-03-01",
      amount: 200,
      plan: "Premium",
    },
  ]);

  const handleCheckboxChange = (id) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, checkbox: !row.checkbox } : row
      )
    );
  };

  const handleDownloadClick = (id) => {
    // Implement your download logic here, based on the row id
    console.log(`Download clicked for item with id ${id}`);
  };

  return (
    <BaseComponent>
      <>
        <div className="main">
          <div className="head">
            <h1>SUBSCRIPTION PLANS</h1>
            <div className="head-para">
              {/* <p>Choose the plan that suits for you</p> */}
            </div>
          </div>

          <div className="box">
            <div className="card">
              <h2>
                <b>Free</b>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever.
              </p>
              <div className="small-card">
                <h3>Features:</h3>
                <br />
                <input type="checkbox" id="abc" name="feature" />
                <label for="abc">Preloaded Stories</label>
              </div>
              {/* <button className="button">Get Started</button> */}
            </div>
            <div className="card2">
              <div className="top-portion">
                <span>SOME CONTENT HERE</span>
              </div>
              <h2>
                <b>Basic</b>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever.
              </p>
              <div className="small-card2">
                <h3>Features:</h3>
                <br />
                <input type="checkbox" id="abc" name="feature" />
                <label for="abc">Preloaded Stories</label>
                <br />
                <br />
                <input type="checkbox" id="xyz" name="feature" />
                <label for="xyz">Unlimited Stories from Chat GPT</label>
              </div>
              <div className="ending">
                <h3>FOR:</h3>
                <input type="checkbox" id="abc" name="feature" />
                <label for="abc">Monthly</label>
                <br />
                <input type="checkbox" id="xyz" name="feature" />
                <label for="xyz">Yearly</label>
              </div>
              {/* <button className="button">Get Started</button> */}
            </div>
            <div className="card3">
              <h2>
                <b>Premium</b>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text
                ever.
              </p>
              <div className="small-card3">
                <h3>Features:</h3>
                <br />
                <input type="checkbox" id="abc" name="feature" />
                <label for="abc">Preloaded Stories</label>
                <br />
                <br />
                <input type="checkbox" id="xyz" name="feature" />
                <label for="xyz">Unlimited Stories from Chat GPT</label>
              </div>
              <div className="ending">
                <h3>FOR:</h3>
                <input type="checkbox" id="abc" name="feature" />
                <label for="abc">Monthly</label>
                <br />
                <input type="checkbox" id="xyz" name="feature" />
                <label for="xyz">Yearly</label>
              </div>
              {/* <button className="button">Get Started</button> */}
            </div>
          </div>
        </div>
        <div className="part2">
          <div className="bill-heading">
            <h2>Billing History</h2>
            <p>Download your previous plan receipts and history</p>
          </div>
          <div className="table-part">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Check</th>
                  <th>Invoice</th>
                  <th>Billing Date</th>
                  <th>Amount</th>
                  <th>Plan</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.checkbox || false}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                    </td>
                    <td>{row.invoice}</td>
                    <td>{row.billingDate}</td>
                    <td>{row.amount}</td>
                    <td>{row.plan}</td>
                    <td>
                      <button onClick={() => handleDownloadClick(row.id)}>
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </BaseComponent>
  );
};

export default AfterLogin;
