import {
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import teddybear from "../Images/teddybear.jpg";
import BaseComponent from "../BaseComponents/BaseComponent";

const Contact = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic to handle form submission here
    console.log("Form submitted:", formData);
  };

  const styles = {
    contactHeding: {
      color: "#262626",
      fontFamily: "Nunito",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
    },
    contactPara: {
      color: "#636880",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
    },
    imageWrapper: {
      width: "100%",
      height: "auto",
      maxWidth: "500px",
    },
  };

  return (
    <BaseComponent>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Typography variant="h3" sx={styles.contactHeding}>
              Get in Touch with Us
            </Typography>
            <Box width="85%">
              <Typography variant="body1">
                Lorem ipsum dolor sit amet consectetur. Sit risus id placerat
                morbi. Velit pellentesque sed duis id aliquam quis leo congue.
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <Box sx={{ padding: "1rem" }}>
                <TextField
                  // label="Name"
                  placeholder="Your Name"
                  fullWidth
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange("name")}
                  InputProps={{
                    sx: {
                      color: "#636880",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                  required
                />

                <TextField
                  // label="Email ID"
                  fullWidth
                  placeholder="Your Email"
                  margin="normal"
                  type="email"
                  value={formData.email}
                  onChange={handleChange("email")}
                  InputProps={{
                    sx: {
                      color: "#636880",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                  required
                />
                <TextField
                  // label="Mobile No."
                  fullWidth
                  placeholder="Your Phone"
                  margin="normal"
                  value={formData.mobile}
                  onChange={handleChange("mobile")}
                  InputProps={{
                    sx: {
                      color: "#636880",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                  required
                />
                <TextField
                  // label="Message"
                  placeholder="Message here..."
                  InputProps={{
                    sx: {
                      color: "#636880",
                      fontFamily: "Public Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange("message")}
                  required
                />
                <Box marginTop="1rem">
                  <ReCAPTCHA
                    sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={onChange}
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    background: "#00C6FF",
                    color: "#fff",
                    borderRadius: "5px",
                    marginTop: "1rem",
                    "&:hover": {
                      background: "#00C6FF",
                      color: "#fff",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>
          {!isSmallScreen && (
            <Grid
              item
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={styles.imageWrapper}>
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={teddybear}
                  alt="teddybear"
                ></img>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </BaseComponent>
  );
};

export default Contact;
