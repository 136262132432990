import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import Home from "./pages/home";
// import DiscoveryAndCoaches from "./pages/discoveryCoaches";
// import MainLayout from "./layout/MainLayout";
import Main1 from "./MainContent/Main1";
import About from "./AboutUs/About";
import Contact from "./ContactUs/Contact";
import Terms from "./TermsCond/Terms";
import Privacy from "./Privacy/Privacy";
import SignIn from "./SignIn/SignIn";
import Subscription from "./SubscriptionPlans/Subscription";
import AfterLogin from "./AfterLogin/AfterLogin";
import LogOut from "./LogOut/LogOut";
import Faq from "./FAQ/Faq";
import Cookies from "./Cookies/Cookies";
import Cookies2 from "./Cookies/Cookies2";
import Success from "./Successful/Success";
import Error from "./Error/Error";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main1 />}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/privacy" element={<Privacy/>}></Route>
          <Route path="/signin" element={<SignIn />}></Route>
          <Route path="/subscription" element={<Subscription />}></Route>
          <Route path="/afterlogin" element={<AfterLogin/>}></Route>
          <Route path="/logout" element={<LogOut/>}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/cookies" element={<Cookies />}></Route>
          <Route path="/cookies2" element={<Cookies2 />}></Route>
          <Route path="/success" element={<Success />}></Route>
          <Route path="/error" element={<Error />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
