import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BaseComponent from "../BaseComponents/BaseComponent";

import LogOut from "../Images/LogOut.jpg";
import "./LogOut.scss"; 

const BootstrapDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: "2rem",
  },
  "& .MuiDialogActions-root": {
    padding: "1rem",
  },
});

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseComponent>
      <React.Fragment>
        <Button variant="outlined" onClick={handleClickOpen}>
          Open dialog
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="signout-dialog-title"
            id="customized-dialog-title"
          >
            <img src={LogOut} alt="logout" />
            <h3>Logout</h3>
            <p>Do you want to log out?</p>
          </DialogTitle>

          <DialogActions className="signout-dialog-actions">
            <Button onClick={handleClose} style={{ color: "#888" }}>
              No
            </Button>
            <Button
              autoFocus
              onClick={handleClose}
              style={{
                borderRadius: "5px",
                background: "#00C6FF",
                color: "#FFF",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </BaseComponent>
  );
}
