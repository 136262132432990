import React from "react";
import "./Main1.scss";
import headline from "../Images/headline.jpg";
import heybuddy from "../Images/heybuddy.jpg";
import teddybear from "../Images/teddybear.jpg";
import googlep from "../Images/googlep.jpg";
import star from "../Images/star.jpg";
import messagebox from "../Images/messagebox.jpg";
import Main2 from "./Main2";
import BaseComponent from "../BaseComponents/BaseComponent";

const Main1 = () => {
  return (
    <BaseComponent>
      <div className="first-background">
        <div className="flexuu">
          <div className="leftside">
            <div className="teddy-tale">
              <div className="teddytale">
                <img src={headline} alt="" />
              </div>
              <div className="message">
                <p>Discover Teddy Tale Wonders Around You</p>
              </div>
              <div className="message-text">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="playstore">
                <h4>Download Now on</h4>
                <img src={googlep} alt="" />
              </div>
            </div>
          </div>
          <div className="rightside">
            <div className="popup">
              <img src={heybuddy} alt="" />
            </div>
            <div className="teddy">
              <img
                src={teddybear}
                alt=""
                style={{ width: "75%", height: "75%" }}
              />
            </div>
            <div className="boxer">
              <div className="cards">
                <p>
                  <b>10 M+</b>
                </p>
                <p>Downloads</p>
              </div>
              <div className="cards">
                <p>
                  <b>15 M+</b>
                </p>
                <p>Reviews</p>
              </div>
              <div className="cards3">
                <div className="card-div">
                  <img src={star} alt="" />
                  <p>
                    <b>4.5</b>
                  </p>
                </div>
                <p>Ratings</p>
              </div>
            </div>
          </div>
        </div>

        <div className="head2">
          <h2>Start To Use Teddy Tales</h2>
        </div>
        <div className="heading">
          <h4>
            <b>TO REACH CHILDREN, CREATE THEIR KNOW WORLD WITH TEDDY FRIEND</b>
          </h4>
        </div>
        <div className="para">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>

        <div className="leftright2">
          <div className="left2">
            <img
              src={teddybear}
              alt=""
              // style={{ width: "75%", height: "75%" }}
            />
          </div>
          <div className="right2">
            <div className="right2t1">
              <div className="r211">
                <div class="image-container">
                  <img src={messagebox} alt="" />
                  <div class="overlay-content">
                    <h5>DOWNLOAD THE APP</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="r212">
                <div class="image-container">
                  <img src={messagebox} alt="" />
                  <div class="overlay-content">
                    <h5>CHOOSE THE MORALS</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="right2t2">
              <div className="r221">
                <div class="image-container">
                  <img src={messagebox} alt="" />
                  <div class="overlay-content">
                    <h5>SELECT THE STORY TYPES</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="r222">
                <div class="image-container">
                  <img src={messagebox} alt="" />
                  <div class="overlay-content">
                    <h5>CHOOSE YOUR LANGUAGE</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Main2 />
      </div>
    </BaseComponent>
  );
};

export default Main1;
