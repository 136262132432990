import React from "react";
import "./SignIn.scss";
import google from "../Images/google.jpg";
import fb from "../Images/fb.jpg";
import apple from "../Images/apple.jpg";
import BaseComponent from "../BaseComponents/BaseComponent";


const SignIn = () => {
  return (
    <BaseComponent>
      <div className="signin">
        <div className="cardd">
          <h1>SIGN IN</h1>
          <div className="threeLogin">
            <button className="threebtns">
              <img src={google} alt="" />
              <p className="threep">Continue with Google</p>
            </button>
            <button className="threebtns">
              <img src={fb} alt="" />
              <p className="threep">Continue with Facebook</p>
            </button>
            <button className="threebtns">
              <img src={apple} alt="" />
              <p className="threep">Continue with Apple</p>
            </button>
          </div>
          <div className="checkboxpar">
            <input className="checkbox" type="checkbox" />
            <label className="labb">
              By clicking you agree to our{" "}
              <span className="termu">Terms & Conditions</span> and{" "}
              <span className="termu">Privacy Policy</span>
            </label>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default SignIn;
