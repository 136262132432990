import * as React from "react";
import {
  Box,
  Grid,
  Link,
  Typography,
  Container,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AppleIcon from "@mui/icons-material/Apple";
import logo2 from "../Images/logo2.jpg";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";

const socialMediaLinks = {
  facebook: "#",
  twitter: "#",
  instagram: "#",
  youTube: "#",
};

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          color: "text.secondary",
          py: 3,
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Container maxWidth={false}>
          <Grid
            style={{ background: "#FBC8DE" }}
            container
            spacing={2}
            justifyContent="space-between"
            borderRadius={8}
          >
            <Grid item xs={12} sm={6} md={5}>
              <Stack
                sx={{ marginLeft: { xs: "", sm: "", md: "", lg: "5rem" } }}
              >
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <Typography>
                    <img sx={{ width: "100%" }} src={logo2} alt="teddy" />
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: {
                        xs: "1.5rem",
                        sm: "1.5rem",
                        md: "1.5rem",
                        lg: "2rem",
                      },
                    }}
                  >
                    Subscribe To Our Newsletter!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "10px",
                        lg: "12px",
                      },
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                    <br />
                    Lorem Ipsum has been the industry's standard dummy text.
                  </Typography>
                </Typography>

                <Typography mt={2} display="flex">
                  <input
                    style={{
                      padding: "10px",
                      marginRight: "1px",
                      width: "300px",
                    }}
                    type="text"
                    name="text"
                    placeholder="Your email address"
                  />
                  <Button
                    style={{ background: "#00C6FF", color: "white" }}
                    variant="outlined"
                  >
                    Subscribe
                  </Button>
                </Typography>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ background: "#FBC8DE" }}
              sx={{
                display: { xs: "grid", sm: "flex", md: "flex" },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                },
                height: { xs: "600px", sm: "500px" },
                flexWrap: "wrap",
              }}
              mt={5}
            >
              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  gutterBottom
                >
                  <b>Quick Links</b>
                </Typography>
                <Link
                  href="/terms"
                  color="inherit"
                  display="block"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Terms & Conditions
                </Link>
                <Link
                  href="/privacy"
                  color="inherit"
                  display="block"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="/cookies2"
                  color="inherit"
                  display="block"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Testimonials
                </Link>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  gutterBottom
                >
                  <b>Support</b>
                </Typography>
                <Typography
                  style={{ listStyle: "none", textDecoration: "none" }}
                >
                  <Link
                    href="/contact"
                    color="inherit"
                    display="block"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Contact Us
                  </Link>
                  <Link
                    href="/faq"
                    color="inherit"
                    display="block"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    FAQs
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  gutterBottom
                >
                  <b>Follow Us</b>
                </Typography>
                <Typography style={{ width: "2px", height: "2px" }}>
                  <IconButton
                    aria-label="Facebook"
                    href={socialMediaLinks.facebook}
                    style={{ fontSize: "12px" }}
                  >
                    <FacebookIcon
                      style={{ width: "30px", height: "20px", color: "black" }}
                    />{" "}
                    Facebook
                  </IconButton>
                  <IconButton
                    aria-label="Twitter"
                    href={socialMediaLinks.twitter}
                    style={{ fontSize: "12px" }}
                  >
                    <TwitterIcon
                      style={{ width: "30px", height: "20px", color: "black" }}
                    />{" "}
                    Twitter
                  </IconButton>
                  <IconButton
                    aria-label="Instagram"
                    href={socialMediaLinks.instagram}
                    style={{ fontSize: "12px" }}
                  >
                    <InstagramIcon
                      style={{ width: "30px", height: "20px", color: "black" }}
                    />{" "}
                    Instagram
                  </IconButton>
                  <IconButton
                    aria-label="YouTube"
                    href={socialMediaLinks.youTube}
                    style={{ fontSize: "12px" }}
                  >
                    <YouTubeIcon
                      style={{ width: "30px", height: "20px", color: "black" }}
                    />{" "}
                    YouTube
                  </IconButton>
                </Typography>
              </Grid>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginLeft: { xs: "0", sm: "0rem", md: "0rem", lg: "10rem" },
                  marginTop: { xs: "8rem", sm: "-3rem", md: "-2rem" },
                }}
              >
                <Button
                  sx={{
                    color: "white",
                    background: "black",
                    height: "45px",
                    width: {
                      xs: "130px",
                      sm: "180px",
                      md: "180px",
                      lg: "180px",
                    },
                    borderRadius: "10px",
                  }}
                  startIcon={
                    <SlowMotionVideoIcon
                      style={{ height: "30px", width: "35px" }}
                    />
                  }
                >
                  <div style={{ lineHeight: "0.5" }}>
                    <p style={{ fontSize: "8px", whiteSpace: "nowrap" }}>
                      Get In All
                    </p>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        marginLeft: { xs: "-5px" },
                      }}
                    >
                      Google Play
                    </Typography>
                  </div>
                </Button>
                <Button
                  sx={{
                    color: "white",
                    background: "black",
                    height: "45px",
                    width: {
                      xs: "130px",
                      sm: "150px",
                      md: "180px",
                      lg: "180px",
                    },
                    borderRadius: "10px",
                  }}
                  startIcon={
                    <AppleIcon style={{ height: "30px", width: "35px" }} />
                  }
                >
                  <div style={{ lineHeight: "0.5" }}>
                    <Typography
                      sx={{
                        fontSize: "8px",
                        whiteSpace: "nowrap",
                        marginLeft: { xs: "-8px" },
                      }}
                    >
                      Downloaded the App
                    </Typography>
                    <Typography
                      sx={{ fontSize: "12px", marginLeft: { xs: "-8px" } }}
                    >
                      AppStore
                    </Typography>
                  </div>
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ pt: 4 }}
          >
            © 2024 Company Co. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
