import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const BaseComponent = ({children}) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer/>
    </>
  )
}

export default BaseComponent