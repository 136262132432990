import React from "react";
import { useNavigate } from "react-router-dom";
import "./Subscription.scss";
import BaseComponent from "../BaseComponents/BaseComponent";

const Subscription = () => {
  const navigate = useNavigate();
  const handleAfterlogin = () => {
    navigate("/afterlogin");
  };
  return (
    <BaseComponent>
      <div className="main">
        <div className="head">
          <h1>SUBSCRIPTION PLANS</h1>

          <div className="head-para">
            <p>Choose the plan that suits for you</p>
          </div>
        </div>
        <div className="box">
          <div className="card">
            <h2>
              <b>Free</b>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever.
            </p>
            <div className="small-card">
              <h3>Features:</h3>
              <br />
              <input type="checkbox" id="abc" name="feature" />
              <label for="abc">Preloaded Stories</label>
            </div>
            <button className="button">Get Started</button>
          </div>
          <div className="card2">
            <h2>
              <b>Basic</b>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever.
            </p>
            <div className="small-card2">
              <h3>Features:</h3>
              <br />
              <input type="checkbox" id="abc" name="feature" />
              <label for="abc">Preloaded Stories</label>
              <br />
              <br />
              <input type="checkbox" id="xyz" name="feature" />
              <label for="xyz">Unlimited Stories from Chat GPT</label>
            </div>
            <div className="ending">
              <h3>FOR:</h3>
              <input type="checkbox" id="abc" name="feature" />
              <label for="abc">Monthly</label>
              <br />
              <input type="checkbox" id="xyz" name="feature" />
              <label for="xyz">Yearly</label>
            </div>
            <button onClick={handleAfterlogin} className="button">
              Get Started
            </button>
          </div>
          <div className="card3">
            <h2>
              <b>Premium</b>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever.
            </p>
            <div className="small-card3">
              <h3>Features:</h3>
              <br />
              <input type="checkbox" id="abc" name="feature" />
              <label for="abc">Preloaded Stories</label>
              <br />
              <br />
              <input type="checkbox" id="xyz" name="feature" />
              <label for="xyz">Unlimited Stories from Chat GPT</label>
            </div>
            <div className="ending">
              <h3>FOR:</h3>
              <input type="checkbox" id="abc" name="feature" />
              <label for="abc">Monthly</label>
              <br />
              <input type="checkbox" id="xyz" name="feature" />
              <label for="xyz">Yearly</label>
            </div>
            <button className="button">Get Started</button>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default Subscription;
