import React from "react";
import logo from "../Images/logo.jpg";
import "./Navbar.scss";
// import Subscription from "../SubscriptionPlans/Subscription";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const handleSubscription = () => {
    console.log("Subscription clicked");
    navigate("/subscription");
  };
  const handleContact = () => {
    navigate("/contact");
  };
  const handleFaq = () => {
    navigate("/faq");
  };
  const handleSignin = () => {
    navigate("/signin");
  };
  const handleImg = () => {
    navigate("/");
  };
  const handleCookies2 = () => {
    navigate("/cookies2");
  };
  return (
    <div className="navbar">
      <div className="logo">
        <img onClick={handleImg} src={logo} alt="" />
        <div className="navbar-items">
          <ul onClick={handleSubscription}>Subscriptions</ul>
          <ul onClick={handleContact}>Contact Us</ul>
          <ul onClick={handleCookies2}>Testimonials</ul>
          <ul onClick={handleFaq}>FAQs</ul>
        </div>
      </div>

      <div className="sign-in">
        <button onClick={handleSignin} className="sign-in-button">
          Sign In
        </button>
      </div>
    </div>
  );
};

export default Navbar;
