import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import BaseComponent from "../BaseComponents/BaseComponent";
import Error from "../Images/Error.jpg";

const BootstrapDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: "2rem",
  },
  "& .MuiDialogActions-root": {
    padding: "1rem",
  },
});

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseComponent>
      <React.Fragment>
        <Button variant="outlined" onClick={handleClickOpen}>
          Open dialog
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="signout-dialog-title"
            id="customized-dialog-title"
          >
            <img src={Error} alt="error" />
            <h3>We couldn't process with your payment.</h3>
            <button>Please Try Again</button>
          </DialogTitle>
        </BootstrapDialog>
      </React.Fragment>
    </BaseComponent>
  );
}
